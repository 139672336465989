.container{
  position: absolute;
  top: 25%;
  left: 30px;
  width: 300px;
  height: auto;
  border-color: black;
  border-width: 2px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.buttonCol, .panelCol{
  display: flex;
  flex-direction: column;
  gap: 5px
}

.buttonCol{
  max-width: 40px;
}