.container{   
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%,0);
  width: 80vw;
  max-width: 1000px;
  height: 180px;
  border-radius: 10px 10px 0 0 ;
  border-bottom: 10px solid rgba(255,255,255,.9);
  background-color: rgba(255,255,255,.9);
  z-index: 5;
  overflow: hidden;
}

.specs{
  display: flex;
  z-index: 6;
  position: absolute;
  top: 8px;
  right: 10px;
  height:20px;
  line-height: 20px;
  text-align: center;
  font-size: .9em;
  font-weight: 300;
  color: #333333;
}

#reverseButton{
  display: block;
  padding:0;
  margin: 0 0 0 5px;
  border: none;
  border-radius: 5px;
  background: transparent
}
#reverseButton:hover{
  background-color: #BBDAEF;
}
#reverseButton:active{
  background-color: transparent
}
