.hidden{
  display:none;
}
.panel{
  width: 250px;
  height: auto;
  border-radius: 5px;
  background-color: rgba(255,255,255,.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 5px; */
}

.panel h2{
  font-size: 1.1em;
  font-weight: 500;
  color: #333333;
  margin: 10px 0;
}

.text1{
  font-size: .9em;
  font-weight: 300;
  color: #333333;
}
.hint{
  margin: 7px 0 15px;
  font-size: .9em;
  font-weight: 200;
  color: #333333;
  text-shadow: 1px 1px 20px #333333
}
.buttons{
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.buttons button{
  width: 45px;
  height: 45px;
  background-color: transparent;
  border-radius: 22.5px;
  border: none;
}
.buttons button:hover{ 
  background-color: #BBDAEF;
}
.buttons button:active{
  background-color: transparent;
}
