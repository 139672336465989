.unToggled, 
.toggled {
  z-index: 1;
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 10px;
  background-color: #FFFFFF;
  transition: all, .3s;
  display: flex;
  align-items: center;
  color: #3C3C3C
}

.toggled{
  border: 2px solid;
  border-radius: 10px;
  border-color: #3C3C3C;
}

.unToggled:hover{
  width: 130px;
  padding-left: 5px;
  box-shadow: 0px 0px 10px #3C3C3C;
}

.unToggled .name, 
.toggled .name {
  opacity: 0;
}

.unToggled:hover .name{
  transition-delay: .23s;
  opacity: 1;
  padding-left: 40px;
}

.unToggled img,
.toggled img {
  position: absolute;
  left: 0;
  width: 34px;
  padding: 3px
}