.untoggled, 
.toggled {
  z-index: 1;
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 10px;
  background-color: #FFFFFF;
  transition: all, .3s;
  display: flex;
  align-items: center;
  color: #3C3C3C
}

.toggled{
  border: 2px solid;
  border-radius: 10px;
  border-color: #3C3C3C;
}

.untoggled:hover{
  width: 130px;
  padding-left: 5px;
  box-shadow: 0px 0px 10px #3C3C3C;
}

.untoggled .name, 
.toggled .name {
  opacity: 0;
}

.untoggled:hover .name{
  transition-delay: .23s;
  opacity: 1;
  padding-left: 40px;
}

.untoggled img,
.toggled img {
  position: absolute;
  left: 0;
  width: 34px;
  padding: 3px
}

.panel-hidden{
  display:none;
}

.panel{
  width: 250px;
  height: auto;
  border-radius: 5px;
  background-color: rgba(255,255,255,.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 5px; */
}

.panel h2{
  font-size: 1.1em;
  font-weight: 500;
  color: #333333;
  margin: 10px 0;
}